import { FC, ReactNode, useState } from "react"

interface ModalActions {
  isOpen: boolean
  close: () => void
}

interface Props {
  renderButton: (open: () => void) => ReactNode
  renderModal: ({ isOpen, close }: ModalActions) => ReactNode
}

const ButtonWithModal: FC<Props> = ({ renderButton, renderModal }) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return (
    <>
      {renderButton(open)}
      {renderModal({ isOpen, close })}
    </>
  )
}

export default ButtonWithModal
