import { Space } from "antd"
import FilterAccount from "../data/filters/FilterAccount"
import { joinAccount, useAccount } from "../data/filters/FilterAccount"
import { useAccounts } from "../data/queries/Accounts"
import { Balances as BalancesTable } from "../data/queries/Balances"

const Balances = () => {
  const { data: accounts } = useAccounts()
  const account = useAccount()

  return (
    <Space direction="vertical">
      {accounts && (
        <FilterAccount accounts={accounts.map(joinAccount).sort()} />
      )}
      {account && <BalancesTable account={account} />}
    </Space>
  )
}

export default Balances
