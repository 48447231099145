import { Input, InputNumber, Select, Switch } from "antd"
import { Form, Modal } from "antd"
import { OptionProps } from "rc-select/lib/Option"
import { StrategyType } from "../StrategyTypes"
import { OrderSide, CandleType } from "./Strategies"
import { useMutateStrategies, getBidAsk } from "./Strategies"

export interface StrategyOption {
  id?: string
  symbols: string[]
  accounts: string[]
  on: boolean
  [key: string]: any
}

interface SetProps {
  type: StrategyType
  initialValues: StrategyOption
  isOpen: boolean
  close: () => void
}

export const SetStrategy = ({ type, isOpen, close, ...props }: SetProps) => {
  const { initialValues } = props
  const [form] = Form.useForm<StrategyOption>()
  form.setFieldsValue({
    symbols: initialValues.symbols,
    accounts: initialValues.accounts,
  })
  const { set } = useMutateStrategies(type)

  const submit = async () => {
    const data = await form.validateFields()
    set.mutate(data)
    form.resetFields()
    close()
  }

  const renderSelect = (
    name: string,
    label: string,
    options: OptionProps[]
  ) => (
    <Form.Item name={name} label={label} rules={[{ required: true }]}>
      <Select>
        {options.map((option) => (
          <Select.Option {...option} key={option.value} />
        ))}
      </Select>
    </Form.Item>
  )

  const renderString = (name: string, label: string) => (
    <Form.Item name={name} label={label} rules={[{ required: true }]}>
      <Input />
    </Form.Item>
  )

  const renderNumber = (name: string, label: string) => (
    <Form.Item name={name} label={label} rules={[{ required: true }]}>
      <InputNumber />
    </Form.Item>
  )

  const sideOptions = Object.values(OrderSide).map((side) => {
    return { value: side, children: getBidAsk(side) }
  })

  const candleOptions = Object.values(CandleType).map((type) => ({
    value: type,
    children: type,
    disabled:
      (initialValues.candleType === CandleType.MANUAL) !==
      (type === CandleType.MANUAL),
  }))

  const renderContents = {
    [StrategyType.MARKETMAKING]: () => (
      <>
        {renderSelect("side", "Side", sideOptions)}
        {renderString("amount", "Amount")}
        {renderString("spread", "Spread")}
        {renderNumber("lockMin", "Lock Min")}
      </>
    ),

    [StrategyType.FUTMARKETMAKING]: () => (
      <>
        {renderSelect("side", "Side", sideOptions)}
        {renderString("amount", "Amount")}
        {renderString("spread", "Spread")}
        {renderNumber("orderCount", "NumOfOrders")}
      </>
    ),

    [StrategyType.LIMITTRADING]: () => (
      <>
        {renderSelect("side", "Side", sideOptions)}
        {renderString("maxAmount", "Max Amount")}
        {renderString("targetPrice", "Target Price")}
      </>
    ),

    [StrategyType.FIBONACCI]: () => (
      <>
        {renderSelect("candleType", "Candle Type", candleOptions)}
        {renderString("bidAmount", "Bid Amount")}
        {renderString("askAmount", "Ask Amount")}
        {renderNumber("bidLockMin", "Bid Lock Min")}
        {renderNumber("askLockMin", "Ask Lock Min")}
        {initialValues.candleType === CandleType.MANUAL ? (
          <>
            {renderNumber("minPrice", "Min Price")}
            {renderNumber("maxPrice", "Max Price")}
          </>
        ) : (
          renderNumber("candleCount", "Candle Count")
        )}
      </>
    ),

    [StrategyType.ARBITRAGE]: () => (
      <>
        {renderString("amount", "Amount")}
        {renderString("spread", "Spread")}
      </>
    ),

    [StrategyType.SPREADOUT]: () => (
      <>
        {renderString("amount", "Amount")}
        {renderString("spread", "Spread")}
        {renderNumber("maxNumTick", "Max Ticks")}
      </>
    ),
  }

  return (
    <Modal
      title={`${initialValues.id ? "Edit" : "Add"} strategy`}
      visible={isOpen}
      onOk={submit}
      onCancel={close}
    >
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        autoComplete="off"
      >
        {initialValues.id && (
          <Form.Item name="id" label="Id">
            <Input readOnly />
          </Form.Item>
        )}

        <Form.Item name="symbols" label="Symbols" rules={[{ required: true }]}>
          <Input readOnly />
        </Form.Item>

        <Form.Item
          name="accounts"
          label="Accounts"
          rules={[{ required: true }]}
        >
          <Input readOnly />
        </Form.Item>

        {renderContents[type]()}

        <Form.Item name="on" label="On" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}
