import { Layout } from "antd"
import { Nav, Routes } from "./routes"

const App = () => {
  return (
    <Layout>
      <Layout.Sider breakpoint="lg" collapsedWidth="0">
        <Nav />
      </Layout.Sider>

      <Layout.Content style={{ padding: 24, minHeight: "100vh" }}>
        <Routes />
      </Layout.Content>
    </Layout>
  )
}

export default App
