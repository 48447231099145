import { useQuery } from "react-query"
import { gql, request } from "graphql-request"
import { Table } from "antd"
import { useNetwork } from "../network"
import { round } from "../../lib/math"

interface BalanceData {
  asset: string
  available: string
  used: string
  total: string
}

const BROWSE = gql`
  query Balances($provider: String!, $user: String!) {
    balances(provider: $provider, user: $user) {
      asset
      available
      used
      total
    }
  }
`

const useBalances = (params: TerooAccount) => {
  const network = useNetwork()
  return useQuery(["Balances", params], async () => {
    const { balances } = await request<{ balances: BalanceData[] }>(
      network + "?Balances",
      BROWSE,
      params
    )

    return balances
  })
}

export const Balances = ({ account }: { account: TerooAccount }) => {
  const { data, isLoading } = useBalances(account)

  return (
    <Table
      loading={isLoading}
      rowKey="asset"
      columns={[
        { dataIndex: "asset", title: "asset", align: "center" },
        {
          dataIndex: "available",
          title: "available",
          align: "right",
          render: roundBalance,
        },
        {
          dataIndex: "used",
          title: "used",
          align: "right",
          render: roundBalance,
        },
        {
          dataIndex: "total",
          title: "total",
          align: "right",
          render: roundBalance,
        },
      ]}
      dataSource={data}
      pagination={false}
      size="small"
      bordered
    />
  )
}

export const roundBalance = (balance: string) => round(balance, 4)
