import { atom, useRecoilValue } from "recoil"

const networkState = atom({
  key: "networkState",
  default: "https://terra.teroo.money:5853/graphql",
})

export const useNetwork = () => {
  return useRecoilValue(networkState)
}
