import BigNumber from "bignumber.js"
import { useQuery } from "react-query"
import { gql, request } from "graphql-request"
import { Table } from "antd"
import { useNetwork } from "../network"

interface OpenOrderData {
  strategy: string
  id: string
  clientId: string
  symbol: string
  price: string
  amount: string
  filled: string
  side: string
}

const BROWSE = gql`
  query OpenOrders($provider: String!, $user: String!, $symbol: String!) {
    openOrders(provider: $provider, user: $user, symbol: $symbol) {
      strategy
      id
      clientId
      symbol
      price
      amount
      filled
      side
    }
  }
`

const useOpenOrders = (params: Filters) => {
  const network = useNetwork()
  return useQuery(["OpenOrders", params], async () => {
    const { openOrders } = await request<{ openOrders: OpenOrderData[] }>(
      network + "?OpenOrders",
      BROWSE,
      params
    )

    return openOrders.sort(({ price: a }, { price: b }) =>
      new BigNumber(b).minus(a).toNumber()
    )
  })
}

export const OpenOrders = ({ filters }: { filters: Filters }) => {
  const { data, isLoading } = useOpenOrders(filters)

  return (
    <Table
      loading={isLoading}
      rowKey="id"
      columns={[
        {
          dataIndex: "price",
          align: "right",
          render: (price) => new BigNumber(price).toString(),
        },
        {
          dataIndex: "amount",
          align: "right",
          render: (amount) => new BigNumber(amount).toString(),
        },
      ]}
      dataSource={data}
      pagination={false}
      showHeader={false}
      size="small"
      bordered
    />
  )
}
