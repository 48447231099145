import { useQuery } from "react-query"
import { gql, request } from "graphql-request"
import { Table } from "antd"
import { useNetwork } from "../network"

interface RefPriceData {
  tether: string
  kp: string
}

const READ = gql`
  query RefPrice {
    refPrice {
      tether
      kp
    }
  }
`

const useRefPrice = () => {
  const network = useNetwork()
  return useQuery("RefPrice", async () => {
    const { refPrice } = await request<{ refPrice: RefPriceData }>(
      network + "?RefPrice",
      READ
    )

    return refPrice
  })
}

export const RefPrice = () => {
  const { data, isLoading } = useRefPrice()
  const dataSource =
    data && Object.entries(data).map(([ref, price]) => ({ ref, price }))

  return (
    <Table
      loading={isLoading}
      rowKey="ref"
      columns={[
        { dataIndex: "ref", title: "Ref" },
        { dataIndex: "price", title: "Price", align: "right" },
      ]}
      dataSource={dataSource}
      pagination={false}
      size="small"
      bordered
    />
  )
}
