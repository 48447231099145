import { render } from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { RecoilRoot } from "recoil"
import "./index.scss"
import App from "./App"

const client = new QueryClient()

render(
  <BrowserRouter>
    <RecoilRoot>
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </BrowserRouter>,
  document.getElementById("root")
)
