import { atom, useRecoilState } from "recoil"
import { Select } from "antd"
import { useEffect } from "react"

export const symbolState = atom({
  key: "symbolState",
  default: "",
})

const FilterSymbol = ({ symbols }: { symbols: string[] }) => {
  const [symbol, setSymbol] = useRecoilState(symbolState)

  useEffect(() => {
    const [head] = symbols ?? []
    setSymbol(head)
  }, [symbols, setSymbol])

  return !symbol ? null : (
    <Select value={symbol} onChange={setSymbol}>
      {(symbols ?? []).map((symbol) => {
        return (
          <Select.Option value={symbol} key={symbol}>
            {symbol}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default FilterSymbol
