import { useMutation, useQuery, useQueryClient } from "react-query"
import { gql, request } from "graphql-request"
import { Space, Switch, Table } from "antd"
import { Button, Popconfirm } from "antd"
import { ColumnsType } from "antd/lib/table"
import { DeleteOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons"
import { useNetwork } from "../network"
import { StrategyType } from "../StrategyTypes"
import ButtonWithModal from "./ButtonWithModal"
import { SetStrategy, StrategyOption } from "./SetStrategy"
import moment from "moment"

/* types */
export interface StrategyData {
  id: string
  type: StrategyType
  symbols: string[]
  accounts: string[]
  option: Option
  status: object
}

interface Option {
  on: boolean
  [key: string]: any
}

export enum OrderSide {
  BUY = "BUY",
  SELL = "SELL",
}

export enum CandleType {
  MIN1 = "MIN1",
  MIN5 = "MIN5",
  MANUAL = "MANUAL",
}

interface BrowseParams {
  type: StrategyType
  symbols: string[]
  accounts: string[]
}

/* gqldocs */
const BROWSE = gql`
  query Strategies(
    $type: StrategyType!
    $symbols: [String!]!
    $accounts: [String!]!
  ) {
    strategies(type: $type, symbols: $symbols, accounts: $accounts) {
      id
      type
      symbols
      accounts
      option
      status
    }
  }
`

const SET = {
  [StrategyType.MARKETMAKING]: gql`
    mutation SetMarketMakingStrategy(
      $accounts: [String!]!
      $symbols: [String!]!
      $id: String
      $on: Boolean!
      $side: OrderSide!
      $amount: String!
      $spread: String!
      $lockMin: Float!
    ) {
      setMarketMakingStrategy(
        accounts: $accounts
        symbols: $symbols
        id: $id
        on: $on
        side: $side
        amount: $amount
        spread: $spread
        lockMin: $lockMin
      ) {
        id
        type
        symbols
        accounts
        option
        status
      }
    }
  `,

  [StrategyType.FUTMARKETMAKING]: gql`
    mutation SetFutMarketMakingStrategy(
      $accounts: [String!]!
      $symbols: [String!]!
      $id: String
      $on: Boolean!
      $side: OrderSide!
      $amount: String!
      $spread: String!
      $orderCount: Float!
    ) {
      setFutMarketMakingStrategy(
        accounts: $accounts
        symbols: $symbols
        id: $id
        on: $on
        side: $side
        amount: $amount
        spread: $spread
        orderCount: $orderCount
      ) {
        id
        type
        symbols
        accounts
        option
        status
      }
    }
  `,

  [StrategyType.LIMITTRADING]: gql`
    mutation SetLimitTradingStrategy(
      $accounts: [String!]!
      $symbols: [String!]!
      $id: String
      $on: Boolean!
      $side: OrderSide!
      $maxAmount: String!
      $targetPrice: String!
    ) {
      setLimitTradingStrategy(
        accounts: $accounts
        symbols: $symbols
        id: $id
        on: $on
        side: $side
        maxAmount: $maxAmount
        targetPrice: $targetPrice
      ) {
        id
        type
        symbols
        accounts
        option
        status
      }
    }
  `,

  [StrategyType.FIBONACCI]: gql`
    mutation SetFibonacciStrategy(
      $accounts: [String!]!
      $symbols: [String!]!
      $id: String
      $on: Boolean!
      $candleType: CandleType!
      $bidAmount: String!
      $askAmount: String!
      $bidLockMin: Float!
      $askLockMin: Float!
      $candleCount: Float!
    ) {
      setFibonacciStrategy(
        accounts: $accounts
        symbols: $symbols
        id: $id
        on: $on
        candleType: $candleType
        bidAmount: $bidAmount
        askAmount: $askAmount
        bidLockMin: $bidLockMin
        askLockMin: $askLockMin
        candleCount: $candleCount
      ) {
        id
        type
        symbols
        accounts
        option
        status
      }
    }
  `,

  [StrategyType.ARBITRAGE]: gql`
    mutation SetArbitrageStrategy(
      $accounts: [String!]!
      $symbols: [String!]!
      $id: String
      $on: Boolean!
      $amount: String!
      $spread: String!
    ) {
      setArbitrageStrategy(
        accounts: $accounts
        symbols: $symbols
        id: $id
        on: $on
        amount: $amount
        spread: $spread
      ) {
        id
        type
        symbols
        accounts
        option
        status
      }
    }
  `,
  [StrategyType.SPREADOUT]: gql`
    mutation SetSpreadOutStrategy(
      $accounts: [String!]!
      $symbols: [String!]!
      $id: String
      $refresh: Boolean
      $on: Boolean!
      $amount: String!
      $spread: String!
      $maxNumTick: Float!
    ) {
      setSpreadOutStrategy(
        accounts: $accounts
        symbols: $symbols
        id: $id
        refresh: $refresh
        on: $on
        amount: $amount
        spread: $spread
        maxNumTick: $maxNumTick
      ) {
        id
        type
        symbols
        accounts
        option
        status
      }
    }
  `,
}

const SET_FIB_MANUAL = gql`
  mutation SetFibManualStrategy(
    $accounts: [String!]!
    $symbols: [String!]!
    $id: String
    $on: Boolean!
    $bidAmount: String!
    $askAmount: String!
    $bidLockMin: Float!
    $askLockMin: Float!
    $minPrice: Float!
    $maxPrice: Float!
  ) {
    setFibManualStrategy(
      accounts: $accounts
      symbols: $symbols
      id: $id
      on: $on
      bidAmount: $bidAmount
      askAmount: $askAmount
      bidLockMin: $bidLockMin
      askLockMin: $askLockMin
      minPrice: $minPrice
      maxPrice: $maxPrice
    ) {
      id
      type
      symbols
      accounts
      option
      status
    }
  }
`

const REMOVE = gql`
  mutation RemoveStrategy($id: String!, $type: StrategyType!) {
    removeStrategy(id: $id, type: $type)
  }
`

const TOGGLE = gql`
  mutation ToggleStrategies(
    $type: StrategyType!
    $symbols: [String!]!
    $accounts: [String!]!
    $on: Boolean!
  ) {
    toggleStrategies(
      type: $type
      symbols: $symbols
      accounts: $accounts
      on: $on
    )
  }
`

const CANCEL = gql`
  mutation CancelBySymbol(
    $provider: String!
    $symbol: String!
    $user: String!
  ) {
    cancelBySymbol(provider: $provider, user: $user, symbol: $symbol)
  }
`

/* hooks */
const useBrowseStrategies = (params: BrowseParams) => {
  const network = useNetwork()

  return useQuery(["Strategies", params], async () => {
    const { strategies } = await request<{ strategies: StrategyData[] }>(
      network + "?Strategies",
      BROWSE,
      params
    )

    return strategies
      .sort(({ option: a }, { option: b }) =>
        a["spread"] < b["spread"] ? 1 : -1
      )
      .sort(({ option: a }, { option: b }) => (a["side"] > b["side"] ? 1 : -1))
  })
}

export const useMutateStrategies = (type: StrategyType) => {
  const network = useNetwork()
  const queryClient = useQueryClient()
  const onSuccess = () => queryClient.invalidateQueries("Strategies")

  return {
    set: useMutation(
      (params: StrategyOption) =>
        request(
          network + "?" + type,
          params.candleType === CandleType.MANUAL ? SET_FIB_MANUAL : SET[type],
          params
        ),
      { onSuccess }
    ),
    remove: useMutation(
      (params: { id: string; type: StrategyType }) =>
        request(network + "?RemoveStrategy", REMOVE, params),
      { onSuccess }
    ),
    toggle: useMutation(
      (params: {
        type: StrategyType
        symbols: string[]
        accounts: string[]
        on: boolean
      }) => request(network + "?ToggleStrategies", TOGGLE, params),
      { onSuccess }
    ),
    cancel: useMutation(
      (params: { provider: string; symbol: string; user: string }) =>
        request(network + "?CancelStrategies", CANCEL, params),
      { onSuccess }
    ),
  }
}

/* components */
interface Props {
  queryParams: Params
  cancelParams?: { provider: string; user: string; symbol: string }
}

interface Params {
  type: StrategyType
  symbols: string[]
  accounts: string[]
}

export const Strategies = ({ queryParams, cancelParams }: Props) => {
  const { type } = queryParams

  /* queries */
  const { data, isLoading } = useBrowseStrategies(queryParams)

  /* actions */
  const { set, remove, toggle, cancel } = useMutateStrategies(type)

  /* render */
  const columnsByType: Record<StrategyType, Columns> = {
    [StrategyType.MARKETMAKING]: [
      { dataIndex: ["option", "side"], title: "Side", render: getBidAsk },
      { dataIndex: ["option", "amount"], title: "Amount" },
      { dataIndex: ["option", "spread"], title: "Spread" },
      { dataIndex: ["option", "lockMin"], title: "Lock Min" },
      {
        dataIndex: ["status", "unlockTime"],
        title: "Unlock",
        render: getUnlockTs,
      },
      { dataIndex: ["status", "midPrice"], title: "Mid Price" },
      { dataIndex: ["status", "mmPrice"], title: "Order Price" },
    ],

    [StrategyType.FUTMARKETMAKING]: [
      { dataIndex: ["option", "side"], title: "Side", render: getBidAsk },
      { dataIndex: ["option", "amount"], title: "Amount" },
      { dataIndex: ["option", "spread"], title: "Spread" },
      { dataIndex: ["option", "orderCount"], title: "NumOfOrders" },
      { dataIndex: ["status", "midPrice"], title: "MidPrice" },
      { title: "BestOrderPrice" },
    ],

    [StrategyType.LIMITTRADING]: [
      { dataIndex: ["option", "side"], title: "Side", render: getBidAsk },
      { dataIndex: ["option", "targetPrice"], title: "Target Price" },
      { dataIndex: ["option", "maxAmount"], title: "Max Amount" },
      { dataIndex: ["status", "filledAmount"], title: "Filled Amount" },
      {
        dataIndex: ["status", "type"],
        title: "Completed",
        render: getCompleted,
      },
    ],

    [StrategyType.FIBONACCI]: [
      {
        title: "Candle",
        children: [
          {
            dataIndex: ["option", "candleType"],
            title: "Type",
            align: "center",
          },
          { dataIndex: ["option", "candleCount"], title: "Count" },
        ],
      },
      {
        title: "Price",
        children: [
          { dataIndex: ["option", "minPrice"], title: "Min" },
          { dataIndex: ["option", "maxPrice"], title: "Max" },
        ],
      },
      {
        title: "Amount",
        children: [
          { dataIndex: ["option", "bidAmount"], title: "Bid" },
          { dataIndex: ["option", "askAmount"], title: "Ask" },
        ],
      },
      {
        title: "LockMin",
        children: [
          { dataIndex: ["option", "bidLockMin"], title: "Bid" },
          { dataIndex: ["option", "askLockMin"], title: "Ask" },
        ],
      },
      {
        title: "Unlock",
        children: [{ title: "Bid" }, { title: "Ask" }],
      },
    ],

    [StrategyType.ARBITRAGE]: [
      { dataIndex: ["option", "amount"], title: "Amount" },
      { dataIndex: ["option", "spread"], title: "Spread" },
    ],

    [StrategyType.SPREADOUT]: [
      { dataIndex: ["option", "amount"], title: "Amount" },
      { dataIndex: ["option", "spread"], title: "Spread" },
      { dataIndex: ["option", "maxNumTick"], title: "Max Ticks" },
      {
        dataIndex: ["status", "refreshTime"],
        title: "Refresh",
        render: getUnlockTs,
      },
    ],
  }

  const renderActions = () => {
    const disabled =
      type === StrategyType.ARBITRAGE || type === StrategyType.SPREADOUT

    return (
      <Space>
        <ButtonWithModal
          renderButton={(open) => (
            <Button
              onClick={open}
              disabled={disabled || type === StrategyType.FUTMARKETMAKING}
            >
              Add New
            </Button>
          )}
          renderModal={(actions) => (
            <SetStrategy
              {...actions}
              type={type}
              initialValues={{ ...queryParams, on: false }}
            />
          )}
        />

        {type === StrategyType.FIBONACCI && (
          <ButtonWithModal
            renderButton={(open) => (
              <Button onClick={open} disabled={disabled}>
                Add New (Manual)
              </Button>
            )}
            renderModal={(actions) => (
              <SetStrategy
                {...actions}
                type={type}
                initialValues={{
                  ...queryParams,
                  on: false,
                  candleType: CandleType.MANUAL,
                }}
              />
            )}
          />
        )}

        <Popconfirm
          title={`Sure to active all?`}
          onConfirm={() => toggle.mutate({ ...queryParams, on: true })}
          disabled={disabled}
        >
          <Button disabled={disabled}>Active All</Button>
        </Popconfirm>

        <Popconfirm
          title={`Sure to deactive all?`}
          onConfirm={() => toggle.mutate({ ...queryParams, on: false })}
          disabled={disabled}
        >
          <Button disabled={disabled}>Deactive All</Button>
        </Popconfirm>

        {cancelParams && (
          <Popconfirm
            title={`Sure to cancel all?`}
            onConfirm={() => cancel.mutate(cancelParams)}
          >
            <Button>Cancel All</Button>
          </Popconfirm>
        )}
      </Space>
    )
  }

  return (
    <Space direction="vertical">
      {renderActions()}

      <Space>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={[
            ...alignRight(columnsByType[type]),
            {
              dataIndex: ["option", "on"],
              title: "On/Off",
              render: (value, { id, option }) => (
                <Popconfirm
                  title={`Sure to ${value ? "off" : "on"}?`}
                  onConfirm={() =>
                    set.mutate({ id, ...queryParams, ...option, on: !value })
                  }
                >
                  <Switch checked={value} />
                </Popconfirm>
              ),
              align: "center",
            },
            {
              dataIndex: "id",
              render: (id, { option }) => (
                <>
                  <ButtonWithModal
                    renderButton={(open) => (
                      <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={open}
                        disabled={type === StrategyType.LIMITTRADING}
                      />
                    )}
                    renderModal={(actions) => (
                      <SetStrategy
                        {...actions}
                        type={type}
                        initialValues={{ ...queryParams, ...option, id }}
                      />
                    )}
                  />

                  {type !== StrategyType.SPREADOUT && (
                    <Popconfirm
                      title="Sure to delete?"
                      onConfirm={() => remove.mutate({ id, type })}
                      disabled={type === StrategyType.FUTMARKETMAKING}
                    >
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        danger
                        disabled={type === StrategyType.FUTMARKETMAKING}
                      />
                    </Popconfirm>
                  )}

                  {type === StrategyType.SPREADOUT && (
                    <Popconfirm
                      title="Sure to refresh?"
                      onConfirm={() =>
                        set.mutate({
                          id,
                          ...queryParams,
                          ...option,
                          refresh: true,
                        })
                      }
                    >
                      <Button type="text" icon={<RedoOutlined />} />
                    </Popconfirm>
                  )}
                </>
              ),
              align: "center",
            },
          ]}
          dataSource={data}
          pagination={false}
          size="small"
          bordered
        />
      </Space>
    </Space>
  )
}

export const getBidAsk = (side: OrderSide) =>
  ({ [OrderSide.BUY]: "bid", [OrderSide.SELL]: "ask" }[side])

export const getUnlockTs = (time: number) =>
  time === 0
    ? ""
    : moment(time)
        .add(9, "hours")
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")

export const getCompleted = (type: string) =>
  type === "FINISHED" ? "true" : "false"

type Columns = ColumnsType<StrategyData>
const alignRight = (columns: Columns): Columns =>
  columns.map((column) =>
    Object.assign(
      {},
      column,
      "children" in column
        ? { children: alignRight(column.children) }
        : { align: column.align ?? "right" }
    )
  )
