import { useQuery } from "react-query"
import { gql, request } from "graphql-request"
import { Table } from "antd"
import BigNumber from "bignumber.js"
import { useNetwork } from "../network"

interface DepthItem {
  price: string
  amount: string
}

interface DepthData {
  bids: DepthItem[]
  asks: DepthItem[]
  last: string
}

const READ = gql`
  query Depth($provider: String!, $symbol: String!) {
    depth(provider: $provider, symbol: $symbol) {
      bids {
        price
        amount
      }
      asks {
        price
        amount
      }
      last
    }
  }
`

const useDepth = (params: Filters) => {
  const network = useNetwork()
  return useQuery(["Depth", params], async () => {
    const { depth } = await request<{ depth: DepthData }>(
      network + "?Depth",
      READ,
      params
    )

    return depth
  })
}

export const Depth = ({ filters }: { filters: Filters }) => {
  const { data, isLoading } = useDepth(filters)

  return (
    <>
      {[data?.asks, data?.bids].map((data, index) => (
        <Table
          loading={isLoading}
          rowKey="price"
          columns={[
            { dataIndex: "price", align: "right" },
            {
              dataIndex: "amount",
              align: "right",
              render: (amount) => new BigNumber(amount).toFixed(4),
            },
          ]}
          dataSource={data?.sort(({ price: a }, { price: b }) =>
            new BigNumber(b).minus(a).toNumber()
          )}
          pagination={false}
          showHeader={false}
          size="small"
          bordered
          key={index}
        />
      ))}
    </>
  )
}
