import { useLocation } from "react-router"
import routes from "../routes"

export enum StrategyType {
  MARKETMAKING = "MARKETMAKING",
  FUTMARKETMAKING = "FUTMARKETMAKING",
  LIMITTRADING = "LIMITTRADING",
  FIBONACCI = "FIBONACCI",
  ARBITRAGE = "ARBITRAGE",
  SPREADOUT = "SPREADOUT",
}

export const useStrategyType = () => {
  const { pathname } = useLocation()
  return Object.values(routes).find((route) => route.path === pathname)
    ?.strategyType
}
