import { useQuery } from "react-query"
import { useRecoilValue } from "recoil"
import { Space } from "antd"
import { uniq } from "ramda"
import { gql, request } from "graphql-request"
import { useNetwork } from "../network"
import { StrategyType } from "../StrategyTypes"
import FilterAccount, { accountState, joinAccount } from "./FilterAccount"
import FilterSymbol, { symbolState } from "./FilterSymbol"

const BROWSE = gql`
  query Configs($type: StrategyType!) {
    configs(type: $type) {
      type
      symbols
      accounts
    }
  }
`

export const useConfigs = (type: StrategyType) => {
  const network = useNetwork()

  return useQuery("Configs", async () => {
    const { configs } = await request<{
      configs: { symbols: string[]; accounts: string[] }[]
    }>(network + "?Configs", BROWSE, { type })

    const collect = (key: "symbols" | "accounts") =>
      uniq(configs.reduce<string[]>((acc, item) => [...acc, ...item[key]], []))

    return collect("accounts").reduce((obj: Config, account: string) => {
      const symbols = configs
        .filter((config) => config.accounts[0] === account)
        .map((config) => config.symbols)
        .reduce((arr, symbol) => {
          return [...arr, ...symbol]
        }, [])
      obj[account] = symbols
      return obj
    }, {})
  })
}

const Filters = ({ type }: { type: StrategyType }) => {
  const { data: configs } = useConfigs(type)
  const account = useRecoilValue(accountState)

  return !configs ? null : (
    <Space>
      {type !== StrategyType.ARBITRAGE && (
        <FilterAccount accounts={Object.keys(configs).sort()} />
      )}
      {account && <FilterSymbol symbols={configs[joinAccount(account)]} />}
    </Space>
  )
}

export default Filters

/* helpers */
export const useFilters = () => {
  const account = useRecoilValue(accountState)
  const symbol = useRecoilValue(symbolState)

  if (!(account && symbol)) return undefined
  return { ...account, symbol }
}
