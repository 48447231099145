import { useMutation, useQuery, useQueryClient } from "react-query"
import { Button, Popconfirm, Switch, Table } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { gql, request } from "graphql-request"
import { useNetwork } from "../network"
import ButtonWithModal from "./ButtonWithModal"
import { SetAccount } from "./SetAccount"

const BROWSE = gql`
  query Accounts {
    accounts {
      id
      provider
      user
      apiKey
      secret
      passphrase
      tradable
    }
  }
`

export const useAccounts = () => {
  const network = useNetwork()
  return useQuery("Accounts", async () => {
    const { accounts } = await request<{ accounts: TerooAccountDetails[] }>(
      network + "?Accounts",
      BROWSE
    )

    return accounts
  })
}

const SET = gql`
  mutation SetAccount(
    $provider: String!
    $user: String!
    $apiKey: String!
    $secret: String!
    $passphrase: String
    $tradable: Boolean
  ) {
    setAccount(
      provider: $provider
      user: $user
      apiKey: $apiKey
      secret: $secret
      passphrase: $passphrase
      tradable: $tradable
    ) {
      id
      provider
      user
      apiKey
      secret
      passphrase
      tradable
    }
  }
`

const REMOVE = gql`
  mutation RemoveAccount($id: Float!, $provider: String!, $user: String!) {
    removeAccount(id: $id, provider: $provider, user: $user)
  }
`

export const useMutateAccount = () => {
  const network = useNetwork()
  const queryClient = useQueryClient()
  const onSuccess = () => queryClient.invalidateQueries("Accounts")

  return {
    set: useMutation(
      (params: TerooAccountDetails) =>
        request(network + "?SetAccount", SET, params),
      { onSuccess }
    ),
    remove: useMutation(
      (params: TerooAccountDetails) =>
        request(network + "?RemoveAccount", REMOVE, params),
      { onSuccess }
    ),
  }
}

const Accounts = () => {
  const { data, isLoading } = useAccounts()
  const { set, remove } = useMutateAccount()

  return (
    <Table
      loading={isLoading}
      rowKey="id"
      columns={[
        { dataIndex: "id", title: "ID", align: "center" },
        { dataIndex: "provider", title: "Provider", align: "center" },
        { dataIndex: "user", title: "User", align: "center" },
        { dataIndex: "apiKey", title: "API Key", align: "center" },
        { dataIndex: "secret", title: "Secret", align: "center" },
        { dataIndex: "passphrase", title: "Passphrase", align: "center" },
        {
          dataIndex: "tradable",
          title: "Tradable",
          render: (value, account) => (
            <Popconfirm
              title={`Sure to ${value ? "off" : "on"}?`}
              onConfirm={() => set.mutate({ ...account, tradable: !value })}
            >
              <Switch checked={value} />
            </Popconfirm>
          ),
          align: "center",
        },
        {
          dataIndex: "id",
          render: (id, account) => (
            <>
              <ButtonWithModal
                renderButton={(open) => (
                  <Button type="text" icon={<EditOutlined />} onClick={open} />
                )}
                renderModal={(actions) => (
                  <SetAccount {...actions} initialValues={account} />
                )}
              />

              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => remove.mutate(account)}
              >
                <Button type="text" icon={<DeleteOutlined />} danger />
              </Popconfirm>
            </>
          ),
          align: "center",
        },
      ]}
      dataSource={data}
      pagination={false}
      size="small"
      bordered
    />
  )
}

export default Accounts
