import { useEffect } from "react"
import { atom, useRecoilState, useRecoilValue } from "recoil"
import { Select } from "antd"

export const accountState = atom<TerooAccount | undefined>({
  key: "accountState",
  default: undefined,
})

const FilterAccount = ({ accounts }: { accounts: string[] }) => {
  const [account, setAccount] = useRecoilState(accountState)

  if (account && !accounts.includes(joinAccount(account))) {
    const [head] = accounts
    setAccount(splitAccount(head))
  }

  useEffect(() => {
    const [head] = accounts
    setAccount(splitAccount(head))
    // eslint-disable-next-line
  }, [])

  return !account ? null : (
    <Select
      value={joinAccount(account)}
      onChange={(account) => setAccount(splitAccount(account))}
    >
      {accounts.map((account) => {
        return (
          <Select.Option value={account} key={account}>
            {account}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default FilterAccount

/* helpers */
export const joinAccount = ({ provider, user }: TerooAccount) =>
  [provider, user].join("_")

export const splitAccount = (account: string) => {
  const [provider, user] = account.split("_")
  return { provider, user }
}

export const useAccount = () => {
  return useRecoilValue(accountState)
}
