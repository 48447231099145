import { RouteProps } from "react-router-dom"
import { Switch, Route, useLocation, NavLink } from "react-router-dom"
import { Menu } from "antd"

import Base from "./pages/Base"
// import Arbitrage from "./pages/Arbitrage"
import Balances from "./pages/Balances"
// import Accounts from "./data/queries/Accounts"
import { StrategyType } from "./data/StrategyTypes"

interface RouteItem extends RouteProps {
  path: string
  label: string
  strategyType?: StrategyType
}

const routes: RouteItem[] = [
  {
    path: "/market-making",
    label: "Market Making",
    component: Base,
    strategyType: StrategyType.MARKETMAKING,
  },
  // {
  //   path: "/fut-market-making",
  //   label: "Fut Market Making",
  //   component: Base,
  //   strategyType: StrategyType.FUTMARKETMAKING,
  // },
  {
    path: "/limit-trading",
    label: "Limit Trading",
    component: Base,
    strategyType: StrategyType.LIMITTRADING,
  },
  {
    path: "/spread-out",
    label: "Spread Out",
    component: Base,
    strategyType: StrategyType.SPREADOUT,
  },
  // {
  //   path: "/fibonacci",
  //   label: "Fibonacci",
  //   component: Base,
  //   strategyType: StrategyType.FIBONACCI,
  // },
  // {
  //   path: "/arbitrage",
  //   label: "Arbitrage",
  //   component: Arbitrage,
  //   strategyType: StrategyType.ARBITRAGE,
  // },
  { path: "/balances", label: "Balances", component: Balances },
  // { path: "/accounts", label: "Accounts", component: Accounts },
]

export const Nav = () => {
  const { pathname } = useLocation()
  return (
    <Menu theme="dark" defaultSelectedKeys={[pathname]}>
      {routes.map(({ path, label }) => (
        <Menu.Item key={path}>
          <NavLink to={path}>{label}</NavLink>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export const Routes = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route {...route} exact={route.path === "/"} key={route.path} />
      ))}
    </Switch>
  )
}

export default routes
