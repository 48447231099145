import { Space } from "antd"
import Filters, { useFilters } from "../data/filters/Filters"
import { useStrategyType } from "../data/StrategyTypes"
import { OpenOrders } from "../data/queries/OpenOrders"
import { Depth } from "../data/queries/Depth"
import { Strategies } from "../data/queries/Strategies"
import { RefPrice } from "../data/queries/RefPrice"
import { joinAccount } from "../data/filters/FilterAccount"

const Base = () => {
  const type = useStrategyType()
  const filters = useFilters()

  const queryParams =
    type && filters
      ? { type, symbols: [filters.symbol], accounts: [joinAccount(filters)] }
      : undefined

  return !type ? null : (
    <Space align="start">
      <Space direction="vertical">
        <Filters type={type} />

        {filters && (
          <Space align="start">
            <OpenOrders filters={filters} />
            <Depth filters={filters} />
          </Space>
        )}
      </Space>

      <Space align="start">
        {queryParams && (
          <Strategies queryParams={queryParams} cancelParams={filters} />
        )}

        <RefPrice />
      </Space>
    </Space>
  )
}

export default Base
